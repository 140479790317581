<template>
  <form id="form-medproid" @submit.prevent="validateStep" autocomplete="on">
    <div class="preamble"><slot name="preamble"></slot></div>
    <div class="warning" v-if="missingFields">
      Please verify that you have entered all required information.
    </div>
    <div class="warning" v-if="invalidCredentials">
      Please check your credentials and try again.
    </div>
    <div class="form-row">
      <div class="form-item">
        <label for="firstName" class="required">First Name</label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          autocomplete="given-name"
          required
          v-model="medproid.firstName"
          @change="formHasChanged"
        />
        <div class="help-text">
          Do not include any prefixes such as Mr., Ms., Mrs., or Dr.
        </div>
      </div>
      <div class="form-item">
        <label for="lastName" class="required">Last Name</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          autocomplete="family-name"
          required
          v-model="medproid.lastName"
          @change="formHasChanged"
        />
        <div class="help-text">
          Do not include any suffixes such as Jr., III, or M.D.
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-item">
        <label for="email" class="required">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          autocomplete="email"
          required
          v-model="medproid.email"
          @change="formHasChanged"
        />
      </div>
      <div class="form-item">
        <label for="license" class="required">License Number</label>
        <input
          type="text"
          name="license"
          id="license"
          required
          v-model="medproid.license"
          @change="formHasChanged"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-item">
        <label for="stateOfLicense" class="required">Licensing State</label>
        <select
          name="stateOfLicense"
          id="stateOfLicense"
          required
          v-model="medproid.stateOfLicense"
          @change="formHasChanged"
        >
          <option disabled hidden value="">-- SELECT ONE --</option>
          <option
            v-for="state in StatesInUS"
            :key="state.abbr"
            :value="state.abbr"
            >{{ state.name }}</option
          >
        </select>
      </div>
      <div class="form-item">
        <label for="source" class="required">License Type</label>
        <select
          name="source"
          id="source"
          required
          v-model="medproid.source"
          @change="formHasChanged"
        >
          <option disabled hidden value="">-- SELECT ONE --</option>
          <option value="SLN">State</option>
          <option value="NPI">NPI number</option>
        </select>
      </div>
    </div>
    <div class="postscript"><slot name="postscript"></slot></div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StatesInUS from '@/data/StatesInUS'

export default {
  computed: {
    ...mapGetters(['getMedproid', 'getFormState', 'stateIsValid'])
  },
  data() {
    return {
      missingFields: false,
      invalidCredentials: false,
      medproid: {
        firstName: '',
        lastName: '',
        email: '',
        license: '',
        stateOfLicense: '',
        source: ''
      },
      StatesInUS: StatesInUS
    }
  },
  mounted() {
    if (this.getMedproid) {
      this.medproid = this.getMedproid
    }
  },
  methods: {
    ...mapActions(['setMedproid', 'setFormState', 'setFormStateValidation']),
    validateStep() {
      if (!this.stateIsValid) {
        this.$emit('emitLoadingState', { isLoading: true })
        this.axios
          .post('https://triplepastehcp.pml.dev/api/medproid', this.medproid)
          .then(response => {
            this.missingFields = response.data.missingFields
            this.invalidCredentials = response.data.isInvalid
            this.setMedproid(response.data)
            this.$emit('emitLoadingState', { isLoading: false })
          })
          .catch(error => {
            this.$emit('emitBackendError', { isError: true })
            this.$emit('emitLoadingState', { isLoading: false })
          })
      } else {
        this.setFormState(this.getFormState + 1)
      }
    },
    formHasChanged() {
      this.setFormStateValidation({ n: this.getFormState, v: false })
    }
  }
}
</script>

<style lang="scss"></style>
