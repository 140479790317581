import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  // Store some values in localStorage using vuex-persistedstate
  // to make things that much more frustrating for bad actors.
  // plugins: [
  //   createPersistedState({
  //     key: 'pmlHcpClient',
  //     paths: ['roadblock', 'recentRequest']
  //   })
  // ],
  state: {
    formState: 0,
    formStates: [
      {
        name: 'product',
        requiresValidation: true,
        isValid: false,
        back: false,
        next: 'Get Started'
      },
      {
        name: 'shipping',
        requiresValidation: true,
        isValid: false,
        back: 'Back: Product description',
        next: 'Next: Verify your credentials'
      },
      {
        name: 'medproid',
        requiresValidation: true,
        isValid: false,
        back: 'Back: Edit shipping information',
        next: 'Next: Review your request'
      },
      {
        name: 'review',
        requiresValidation: true,
        isValid: false,
        back: 'Back: Edit credentials',
        next: 'Submit your request'
      },
      {
        name: 'complete',
        requiresValidation: false,
        isValid: false,
        back: false,
        next: false
      }
    ],
    roadblock: false,
    ip: null,
    shipping: null,
    medproid: null,
    questions: [],
    products: [],
    request: null,
    recentRequest: false,
    isRecaptcha: false,
    recaptchaFailed: false,
    emailConsent: true
  },
  mutations: {
    SET_FORMSTATE(state, payload) {
      state.formState = payload.n
    },
    SET_ROADBLOCK(state) {
      state.roadblock = true
    },
    SET_IP(state, payload) {
      state.ip = payload
    },
    SET_SHIPPING(state, payload) {
      state.shipping = payload
      if (!payload.isBlacklisted && !payload.missingFields) {
        const index = state.formStates
          .map(element => element.name)
          .indexOf('shipping')
        state.formStates[index].isValid = true
        state.formState += 1
      }
    },
    SET_MEDPROID(state, payload) {
      state.medproid = payload
      if (!payload.isInvalid && !payload.missingFields) {
        const index = state.formStates
          .map(element => element.name)
          .indexOf('medproid')
        state.formStates[index].isValid = true
        state.formState += 1
      }
    },
    SET_QUESTIONS(state, payload) {
      state.questions = payload
    },
    SET_PRODUCTS(state, payload) {
      state.products = payload
      if (state.products.length > 0) {
        const index = state.formStates
          .map(element => element.name)
          .indexOf('product')
        state.formStates[index].isValid = true
      }
    },
    SET_REQUEST(state, payload) {
      state.request = payload
      if (payload.requestSubmitted) {
        const index = state.formStates
          .map(element => element.name)
          .indexOf('review')
        state.formStates[index].isValid = true
        state.formState += 1
      }
    },
    SET_RECENTREQUEST(state) {
      state.roadblock = true
      state.recentRequest = true
    },
    SET_FORMSTATEVALIDATION(state, payload) {
      state.formStates[payload.n].isValid = payload.v
    },
    SET_RECAPTCHA(state, payload) {
      state.isRecaptcha = payload
    },
    SET_RECAPTCHAFAILED(state, payload) {
      state.recaptchaFailed = payload
    },
    SET_EMAILCONSENT(state, payload) {
      state.emailConsent = payload
    }
  },
  actions: {
    setFormState(context, payload) {
      // setFormState is passed an integer, it creates
      // an object to pass to the mutation.
      context.commit('SET_FORMSTATE', { n: payload })
    },
    setIp(context, payload) {
      if (payload.isBlacklisted) {
        context.commit('SET_ROADBLOCK')
      }
      context.commit('SET_IP', payload)
    },
    setShipping(context, payload) {
      if (payload.isBlacklisted || payload.hasRecentRequest) {
        context.commit('SET_ROADBLOCK')
        if (payload.hasRecentRequest) {
          context.commit('SET_RECENTREQUEST')
        }
      } else {
        context.commit('SET_SHIPPING', payload)
      }
    },
    setMedproid(context, payload) {
      context.commit('SET_MEDPROID', payload)
      if (payload.hasRecentRequest) {
        context.commit('SET_ROADBLOCK')
        context.commit('SET_RECENTREQUEST')
      }
    },
    setQuestions(context, payload) {
      context.commit('SET_QUESTIONS', payload)
    },
    setProducts(context, payload) {
      context.commit('SET_PRODUCTS', payload)
    },
    setRequest(context, payload) {
      context.commit('SET_REQUEST', payload)
    },
    setRecentRequest(context) {
      context.commit('SET_RECENTREQUEST')
    },
    setFormStateValidation(context, payload) {
      context.commit('SET_FORMSTATEVALIDATION', payload)
    },
    setRecaptcha(context, payload) {
      context.commit('SET_RECAPTCHA', payload)
    },
    setRecaptchaFailed(context, payload) {
      context.commit('SET_RECAPTCHAFAILED', payload)
    },
    setEmailConsent(context, payload) {
      context.commit('SET_EMAILCONSENT', payload)
    }
  },
  modules: {},
  getters: {
    getFormState: state => state.formState,
    getQuestions: state =>
      state.questions.length > 0 ? state.questions : false,
    getShipping: state => state.shipping,
    getProducts: state => (state.products.length > 0 ? state.products : false),
    getMedproid: state => state.medproid,
    isRoadblocked: state => state.roadblock,
    hasRecentRequest: state => state.recentRequest,
    questionCount: state => state.questions.length,
    productCount: state => state.products.length,
    currentState: state => state.formStates[state.formState].name,
    stateRequiresValidation: state =>
      state.formStates[state.formState].requiresValidation,
    stateIsValid: state => state.formStates[state.formState].isValid,
    buttonsForStep: state => {
      return {
        back: state.formStates[state.formState].back,
        next: state.formStates[state.formState].next
      }
    },
    madeRecentRequest: state => {
      const lastRequest = state.recentRequest ? state.recentRequest : 0
      const now = Date.now()
      // 28 days * 24 hours * 60 minutes * 60 seconds * 1000 ms
      const timespan = 28 * 24 * 60 * 60 * 1000
      return now - lastRequest < timespan ? true : false
    },
    isRecaptcha: state => state.isRecaptcha,
    isRecaptchaFailed: state => state.recaptchaFailed,
    isEmailConsent: state => state.emailConsent
  }
})
