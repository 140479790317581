<template>
  <div id="product-display">
    <div class="warning" v-if="isRecaptchaFailed">
      Sorry, your recaptcha failed.
    </div>
    <product @emitProducts="handleProducts" />
    <vue-programmatic-invisible-google-recaptcha
      ref="recaptcha1"
      :sitekey="'6LfWxLMbAAAAAGcftiERh146zc_9AQCkIzr-A0Hr'"
      :elementId="'recaptcha1'"
      :badgePosition="'right'"
      :showBadgeMobile="false"
      :showBadgeDesktop="true"
      @recaptcha-callback="recaptchaCallback"
    ></vue-programmatic-invisible-google-recaptcha>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Product from '@/components/site-specific/Product'
import VueProgrammaticInvisibleGoogleRecaptcha from 'vue-programmatic-invisible-google-recaptcha'

export default {
  components: { Product, VueProgrammaticInvisibleGoogleRecaptcha },
  computed: {
    ...mapGetters([
      'getFormState',
      'stateIsValid',
      'isRecaptcha',
      'isRecaptchaFailed'
    ])
  },
  methods: {
    ...mapActions([
      'setProducts',
      'setFormState',
      'setRecaptcha',
      'setRecaptchaFailed'
    ]),
    handleProducts(payload) {
      this.setProducts(payload)
    },
    validateStep() {
      if (this.isRecaptcha) {
        this.setFormState(this.getFormState + 1)
      } else {
        this.$refs.recaptcha1.execute()
      }
    },
    recaptchaCallback(recaptchaToken) {
      this.axios
        .post('https://triplepastehcp.pml.dev/api/recaptcha', {
          token: recaptchaToken
        })
        .then(result => {
          if (result.data.success) {
            this.setRecaptchaFailed(false)
            this.setRecaptcha(true)
            this.setFormState(this.getFormState + 1)
          } else {
            this.setRecaptchaFailed(true)
          }
        })
    }
  }
}
</script>

<style lang="scss"></style>
