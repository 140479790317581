<template>
  <div id="site-complete">
    <p><strong>Thank you for your order</strong></p>
    <p>Orders are shipped out monthly.</p>
  </div>
</template>

<script>
export default {
  mounted() {
    /**
     * Tracking code provided by TargetMediaSolutions.com
     */
    window._adftrack = Array.isArray(window._adftrack)
      ? window._adftrack
      : window._adftrack
      ? [window._adftrack]
      : []
    window._adftrack.push({
      HttpHost: 'a2.adform.net',
      pm: 2423686,
      divider: encodeURIComponent('|'),
      pagename: encodeURIComponent('Thank You-HCP')
    })
    ;(function() {
      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'
      var x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    })()
  }
}
</script>

<style lang="scss"></style>
