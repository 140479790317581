<template>
  <div id="loading">
    <div class="loading-wrapper">
      <div class="loading-content">
        <div class="anim"></div>
        <div class="text"><slot /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
#loading {
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  .loading-wrapper {
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateX(-50%) translateY(-50%);
  }
  .anim {
    border: 0.25rem solid rgba(0, 0, 0, 0.25);
    border-top: 0.25rem solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: inline-block;
    margin: 0;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    animation: loaderanim 0.5s linear infinite;
  }
}
@keyframes loaderanim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
