<template>
  <form id="form-shipping" @submit.prevent="submitShipping" autocomplete="on">
    <div class="preamble"><slot name="preamble"></slot></div>
    <div class="warning" v-if="missingFields">
      Please verify that you have entered all required information.
    </div>
    <div class="form-row">
      <div class="form-item">
        <label for="practice" class="required">Company or Practice Name</label>
        <input
          type="text"
          name="practice"
          id="practice"
          required
          v-model="shipping.practice"
          @change="formHasChanged"
        />
      </div>
      <div class="form-item">
        <label for="address1" class="required">Address</label>
        <input
          type="text"
          name="address1"
          id="address1"
          required
          v-model="shipping.address1"
          @change="formHasChanged"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-item">
        <label for="address2">Address 2</label>
        <input
          type="text"
          name="address2"
          id="address2"
          v-model="shipping.address2"
          @change="formHasChanged"
        />
      </div>
      <div class="form-item">
        <label for="city" class="required">City</label>
        <input
          type="text"
          name="city"
          id="city"
          required
          v-model="shipping.city"
          @change="formHasChanged"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-item">
        <label for="state" class="required">State</label>
        <select
          name="state"
          id="state"
          required
          v-model="shipping.state"
          @change="formHasChanged"
        >
          <option disabled hidden value="">-- SELECT ONE --</option>
          <option
            v-for="state in StatesInUS"
            :key="state.abbr"
            :value="state.abbr"
            >{{ state.name }}</option
          >
        </select>
      </div>
      <div class="form-item">
        <label for="zip" class="required">ZIP/Postal Code</label>
        <input
          type="text"
          name="zip"
          id="zip"
          required
          v-model="shipping.zip"
          @change="formHasChanged"
        />
      </div>
    </div>
    <div class="postscript"><slot name="postscript"></slot></div>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StatesInUS from '@/data/StatesInUS'

export default {
  computed: {
    ...mapGetters(['getShipping', 'getFormState', 'stateIsValid'])
  },
  data() {
    return {
      // @TODO: pull missingFields as a value from VueX instead
      missingFields: false,
      shipping: {
        practice: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      },
      StatesInUS: StatesInUS
    }
  },
  mounted() {
    if (this.getShipping) {
      this.shipping = this.getShipping
    }
  },
  methods: {
    ...mapActions(['setShipping', 'setFormState', 'setFormStateValidation']),
    validateStep() {
      if (!this.stateIsValid) {
        this.$emit('emitLoadingState', { isLoading: true })
        this.axios
          .post('https://triplepastehcp.pml.dev/api/address', this.shipping)
          .then(response => {
            this.missingFields = response.data.missingFields
            this.setShipping(response.data)
            this.$emit('emitLoadingState', { isLoading: false })
          })
          .catch(error => {
            this.$emit('emitBackendError', { isError: true })
            this.$emit('emitLoadingState', { isLoading: false })
          })
      } else {
        this.setFormState(this.getFormState + 1)
      }
    },
    formHasChanged() {
      this.setFormStateValidation({ n: this.getFormState, v: false })
    }
  }
}
</script>

<style lang="scss"></style>
