<template>
  <div id="site-product">
    <div id="triplepaste-product">
      <div class="image">
        <picture>
          <source
            media="(min-width: 768px)"
            srcset="
              @/assets/product-300x400.jpg 1x,
              @/assets/product-600x800.jpg 2x
            "
          />
          <img
            src="@/assets/product-150x150.jpg"
            srcset="
              @/assets/product-150x150.jpg 1x,
              @/assets/product-300x300.jpg 2x
            "
            alt="A tube of Triple Paste"
            width="150"
            height="150"
          />
        </picture>
      </div>
      <div class="text">
        <h1>Order a Sample Tray</h1>
        <p style="color:red;font-size:85%;line-height:1.5em;">
          Due to inventory shortage, we are unable to fulfill Triple Paste
          sample requests from our website until further notice. We truly
          apologize for this inconvenience!
        </p>
        <p>
          Thank you for your interest in Triple Paste® Medicated Diaper Rash
          Cream. Please click below to request product sample. 1 sample tray
          contains 100 0.4oz sample tubes of Triple Paste® Medicated Diaper Rash
          Cream.
        </p>
        <p>
          Our unique, medicated formula contains oat extract and beeswax,
          delivering All-in-One Triple Protection against diaper rash. It
          contains non-nano zinc oxide and is hypoallergenic, fragrance-free,
          paraben-free, preservative-free, phthalate-free, dye-free, and
          talc-free.
        </p>
        <!--
          <p>
            <small
              >Please submit one request per office. Due to limited quantities,
              not all requests will be fulfilled and are subject to availability.
            </small>
          </p>
          <p><small>Sample trays will be shipped out monthly.</small></p>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['productCount'])
  },
  data() {
    return {
      productsObj: [
        { sku: 'SKU12345', quantity: 1, name: 'Product Sample Tray' }
      ]
    }
  },
  mounted() {
    /**
     * If Products have not been populated to the VueX store yet,
     * populate it with a default value (this is the standard
     * behavior for sites that do NOT allow selection of products
     * and quantities).
     *
     * If this is something that will change over time or allow
     * custom selections, then $emit() after the changes are made
     * and the parent component...
     *   @/components/organisms/Product.vue
     * ...will handle the communication with VueX automatically.
     */
    if (this.productCount === 0) {
      this.$emit('emitProducts', this.productsObj)
    }
  }
}
</script>

<style lang="scss"></style>
